<template>
  <Breadcrumb :items="breadcrumbs" />
  <h1 class="title title--main title--page mb-3 text-center">Регистрация</h1>
  <form class="form-reg" @submit.prevent="signup" @keydown="form.onKeydown($event)">
    <img src="@/assets/img/form-bg.png" class="form-in__bg" alt="">
    <div class="form__subtitle">
      Введите данные для входа или
      <router-link :to="{ name: 'login' }" class="link link--small font-wieght-600">{{ t('signin') }}</router-link>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-label">{{ t('name') }}</div>
          <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }" >
          <div v-if="form.errors.has('name')" v-html="form.errors.get('name')" class="invalid-feedback" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-label">{{ t('email') }}</div>
          <input v-model="form.email" type="email" name="email" class="form-control" :class="{ 'is-invalid': form.errors.has('email') }" >
          <div v-if="form.errors.has('email')" v-html="form.errors.get('email')" class="invalid-feedback" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-label">{{ t('password') }}</div>
          <input v-model="form.password" type="password" name="password" class="form-control" :class="{ 'is-invalid': form.errors.has('password') }">
          <div v-if="form.errors.has('password')" v-html="form.errors.get('password')" class="invalid-feedback"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-label">{{ t('confirm_password') }}</div>
          <input v-model="form.password_confirmation" type="password" name="password_confirmation" class="form-control" :class="{ 'is-invalid': form.errors.has('password_confirmation') }">
          <div v-if="form.errors.has('password_confirmation')" v-html="form.errors.get('password_confirmation')" class="invalid-feedback"/>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center form__footer">
        <button class="btn btn--blue" type="submit">{{ t('register') }}</button>
      </div>
    </div>
  </form>
</template>
<script>
import { useI18n } from 'vue-i18n'
import { ref } from '@vue/runtime-core'
import Form from 'vform'
import { useMeta } from 'vue-meta'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const { t } = useI18n()
    const store = useStore()
    const router = useRouter()
    useMeta({
      title: t('register')
    })
    const form = ref(new Form({
      name: '',
      email: '',
      password: '',
      password_confirmation: ''
    }))
    const mustVerifyEmail = ref(false)
    // methods
    async function signup () {
      const { data } = await form.value.post(process.env.VUE_APP_API_URL + '/signup')
      // Must verify email
      if (data.status) {
        mustVerifyEmail.value = true
      } else {
        // Log in the user
        const { data: { token } } = await this.form.post(process.env.VUE_APP_API_URL + '/signin')
        // save token
        store.dispatch('auth/saveToken', { token })
        // Update the user
        await store.dispatch('auth/updateUser', { user: data })
        router.push({ name: 'account' })
      }
    }
    return {
      t,
      form,
      signup,
      breadcrumbs: [
        { name: t('home'), href: '/' },
        { name: t('signup'), href: null, active: true }
      ]
    }
  }
}
</script>
